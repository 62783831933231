$(document).on("turbolinks:load", function (){

  if ( document.querySelector('body.maps.show') && document.getElementById('map-show-static-canvas') ) {
    var mapShowStaticCanvas = new fabric.StaticCanvas('map-show-static-canvas');
    window.addEventListener('resize', resizeStaticCanvas);

    var latest_map = document.getElementById('map_revision_latest_canvas_string').value
    if (latest_map === "no_canvas_data") {
      // No canvas data exists—load the basemap instead.
      var basemapUrl = document.getElementById('map_basemap_url').value;
      fabric.Image.fromURL(basemapUrl, function(oImg) {
        mapShowStaticCanvas.setBackgroundImage(oImg, mapShowStaticCanvas.renderAll.bind(mapShowStaticCanvas));
        resizeStaticCanvas();
      }, { crossOrigin: 'anonymous' });
    } else {
      // Load canvas data normally.
      mapShowStaticCanvas.loadFromJSON(latest_map, function() {
        mapShowStaticCanvas.renderAll();
        resizeStaticCanvas();
      });
    }

    function resizeStaticCanvas() {
      // jsfiddle.net/wq3pyhhu/8/
      var canvasSizer = document.getElementById("canvas-row");
      var width = canvasSizer.offsetWidth;
      var ratio = mapShowStaticCanvas.backgroundImage.width / mapShowStaticCanvas.backgroundImage.height;
      var scale = mapShowStaticCanvas.backgroundImage.scaleX;
      var height = width / ratio
      var zoom = (width / mapShowStaticCanvas.backgroundImage.width) / scale;
      mapShowStaticCanvas.setDimensions({ width: width, height: height });
      mapShowStaticCanvas.setViewportTransform([zoom , 0, 0, zoom , 0, 0])
    };

    // var imageLink = document.getElementById("map-show-image-link");
    // imageLink.addEventListener("click", function() {
    //   var dataURL = mapShowStaticCanvas.toDataURL({
    //     multiplier: 3,
    //     format: 'jpeg',
    //     quality: 1
    //   });
    //   // https://stackoverflow.com/questions/5403709/convert-canvas-to-image-and-open-in-new-window-using-ruby-on-rails-and-javascrip
    //   // open().document.write('<img src="'+dataURL+'"/>');
    //   open().document.body.innerHTML = '<img src="'+dataURL+'"/>'
    // });

  };
});
